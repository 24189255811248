import { render, staticRenderFns } from "./TreatBigSale.vue?vue&type=template&id=61f54356"
import script from "./TreatBigSale.vue?vue&type=script&lang=js"
export * from "./TreatBigSale.vue?vue&type=script&lang=js"
import style0 from "./TreatBigSale.vue?vue&type=style&index=0&id=61f54356&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports